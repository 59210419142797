import { ArrowRight, Compass, Users, Star, Instagram, Twitter, Camera } from 'lucide-react';

export default function LandingPage() {
  const totalImages = 14; // Adjust this to the number of images you have
const randomImage = `background_${Math.floor(Math.random() * totalImages) + 1}.jpg`;


  return (
    <div className="relative min-h-screen bg-gray-900 text-white overflow-x-hidden">
      {/* Static Background Image */}
      <div
        className="fixed inset-0 z-0"
        style={{
          backgroundImage: `url(background_images/${randomImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="absolute inset-0 bg-black opacity-75"></div>
      </div>

      {/* Overlay Gradients for Depth */}
      <div className="absolute inset-0 z-0">
        <div className="absolute inset-0 bg-gradient-radial from-transparent to-black opacity-30"></div>
        <div className="absolute top-1/4 left-1/4 w-1/2 h-1/2 bg-gradient-radial from-[#FF9200] to-transparent opacity-10"></div>
        <div className="absolute bottom-1/4 right-1/4 w-1/2 h-1/2 bg-gradient-radial from-[#FF0415] to-transparent opacity-10"></div>
      </div>

      {/* Navigation */}
      <nav className="fixed w-full z-50 flex justify-between items-center p-6 lg:p-8 backdrop-blur-md bg-black/30">
        <div>
          <img src="/logo_vamos.png" alt="VAMOS Logo" className="h-8" />
        </div>
        <div className="flex space-x-4">
          <a
            href="https://vamos-testing.site/"
            className="bg-white text-black hover:bg-gray-200 rounded-full px-8 py-2 text-lg transition-all"
          >
            Join Free
          </a>
        </div>
      </nav>

      {/* Main Content */}
      <main className="relative z-10 pt-32 px-6 lg:px-8 max-w-7xl mx-auto">
        <div className="flex flex-col-reverse md:flex-row items-center md:items-start gap-16 mb-24">
          {/* Text Section */}
          <div className="md:w-1/2 text-left">
            <h1 className="text-5xl md:text-6xl lg:text-7xl font-bold mb-6">
              Where friends
              <br />
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#FF0415] via-[#FF4D00] to-[#FF9200]">
                find fun!
              </span>
            </h1>
            <p className="text-xl lg:text-2xl mb-8 text-white">
              Discover the hottest spots for food, drinks, and unforgettable experiences, curated by your social circle.
              <br className="hidden lg:block" />
              <br />
              <br />
              Our intelligent AI makes planning effortless — no hassle, just good times.
            </p>
            <a
              href="https://vamos-testing.site/"
              className="bg-gradient-to-r from-[#FF4D00] to-[#FF9200] hover:from-[#FF9200] hover:to-[#FF4D00] text-white px-8 py-4 rounded-full text-lg font-bold inline-flex items-center transition-all"
            >
              Let's Go!
              <ArrowRight className="ml-3 w-5 h-5" />
            </a>
          </div>
        </div>

        {/* Features Section */}
        <div className="grid md:grid-cols-3 gap-12 mt-24 mb-32">
          {/* Feature 1 */}
          <div className="bg-white/10 p-8 rounded-xl backdrop-blur-sm transform transition-transform duration-300 hover:scale-105 border border-white/20">
            <Compass className="w-16 h-16 mb-6 text-white" />
            <h3 className="text-2xl font-semibold mb-4 text-white">Discover & Explore</h3>
            <p className="text-white text-lg">
            Whether it’s a casual dinner, a big night out, or a spontaneous adventure, explore places your friends rate and discover new favorites together.
            </p>
          </div>

          {/* Feature 2 */}
          <div className="bg-white/10 p-8 rounded-xl backdrop-blur-sm transform transition-transform duration-300 hover:scale-105 border border-white/20">
            <Users className="w-16 h-16 mb-6 text-white" />
            <h3 className="text-2xl font-semibold mb-4 text-white">Make Plans Happen</h3>
            <p className="text-white text-lg">
            Suggest spots, see who's keen, and lock in plans with just one tap. No more group chat drama, just easy planning for great times.
            </p>
          </div>

          {/* Feature 3 */}
          <div className="bg-white/10 p-8 rounded-xl backdrop-blur-sm transform transition-transform duration-300 hover:scale-105 border border-white/20">
            <Star className="w-16 h-16 mb-6 text-white" />
            <h3 className="text-2xl font-semibold mb-4 text-white">Tailored to Your Circles</h3>
            <p className="text-white text-lg">
            It’s all about the vibe, with personalised recommendations that match your friend groups' styles, ensuring every hangout is unforgettable.
            </p>
          </div>
          {/* Feature 4 */}
          <div className="bg-white/10 p-8 rounded-xl backdrop-blur-sm transform transition-transform duration-300 hover:scale-105 border border-white/20">
            <Camera className="w-16 h-16 mb-6 text-white" />
            <h3 className="text-2xl font-semibold mb-4 text-white">Share Your Moments</h3>
            <p className="text-white text-lg">
            The place to dump all those aesthetic food pics and memories with friends, relive the best times and share to the discover feed for extra Vamos points!
            </p>
          </div>
        </div>
      </main>

      {/* Footer */}
      <footer className="relative z-10 bg-black/40 backdrop-blur-md text-gray-300 py-12 px-6 lg:px-8">
        <div className="max-w-7xl mx-auto flex flex-wrap justify-between items-center">
          {/* Links */}
          <div className="flex flex-col space-y-4 mb-8 md:mb-0">
            <a href="https://vamos-testing.site/" className="hover:text-[#FF4D00] text-lg transition-colors">
              Vamos Perks
            </a>
            <a href="https://vamos-testing.site/" className="hover:text-[#FF4D00] text-lg transition-colors">
              Need a Hand?
            </a>
          </div>
          <div className="flex flex-col space-y-4 mb-8 md:mb-0">
            <a href="https://vamos-testing.site/terms_of_use" className="hover:text-[#FF4D00] text-lg transition-colors">
              The Fine Print
            </a>
            <a href="https://vamos-testing.site/privacy_policy" className="hover:text-[#FF4D00] text-lg transition-colors">
              Your Privacy
            </a>
          </div>
          {/* Social Icons */}
          <div className="flex space-x-6">
            <a
              href="https://www.instagram.com/vamosmates"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-[#FF4D00] transition-colors"
            >
              <Instagram className="h-8 w-8" />
            </a>
            <a
              href="https://twitter.com/Vamos738762"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-[#FF4D00] transition-colors"
            >
              <Twitter className="h-8 w-8" />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}
